<template>
  <div style="display: flex;margin-top: 120px;margin-bottom: 120px;flex-direction: column;">
    <img class="mx-auto" src="https://cenoteszapote.com/logo-zapotes.png" alt="CenotesZapote" width="300px">
    <v-card style="min-width: 400px; max-width: 500px;margin:auto;" >
      <v-card-title class="primary white--text">
        Login
        <!-- <v-btn
            :disabled="!valid"
            color="white"
            class="ml-auto"
            plain
            small
            @click="validate"
          >
            Regresar al sitio
          </v-btn> -->
        <!-- <a class="ml-auto white--text" href="https://cenoteszapote.com">Regresar al sitio</a> -->
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="form-login"
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
            outlined
            dense
          ></v-text-field>
          <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Password"
              hint="At least 8 characters"
              counter
              outlined
              dense
              @click:append="show1 = !show1"
          ></v-text-field>
          <p v-if="error" class="text-danger">{{error.message}}</p>
          <div class="d-flex">
            <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4"
              @click="validate"
            >
              Login
            </v-btn>
            <v-btn
              v-if="isDivers"
              color="primary"
              class="ml-auto"
              outlined
              @click="validate"
            >
              Registrarse
            </v-btn>
          </div>

          <!-- <v-btn
            color="error"
            class="mr-4"
            @click="reset"
          >
            Reset Form
          </v-btn>

          <v-btn
            color="warning"
            @click="resetValidation"
          >
            Reset Validation
          </v-btn> -->
        </v-form>
      </v-card-text>
    </v-card>
    <div style="min-width: 400px; max-width: 500px;margin:auto; text-align:center">
      <a
        :disabled="!valid"
        color="primary"
        class="my-2 d-block v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light"
        href="#"
        @click.stop="dialog = true"
      >
        Olvide mi contraseña
      </a>
      <a v-if="isDivers" class="mx-auto" href="https://cenoteszapote.com">Regresar a CenotesZapote.com</a>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Restablecer contraseña
        </v-card-title>

        <v-card-text>
          <v-form
            ref="formResetPass"
            v-model="validPass"
            lazy-validation
          >
            <v-text-field
              v-model="emailReset"
              :rules="emailRules"
              label="Ingrese su email"
              :messages="['Le enviaremos un correo para restablecer su contraseña']"
              required
              outlined
              dense
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color=""
            @click="dialog = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="primary"
            @click="resetPassword"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.status"
      :timeout="10000"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import firebase from 'firebase/app';
  import 'firebase/auth';
  import User from '@/models/User.js';
  import lodash from 'lodash';
  export default {
    data: () => ({
      validPass:true,
      loading:null,
      error:null,
      valid: true,
      dialog: false,
      isDivers: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
      ],
      show1: false,
      rules: {
        required: value => !!value || 'El password es requerido.',
        min: v => v.length >= 6 || 'Min 6 caracteres',
        emailMatch: () => ('The email and password you entered don\'t match'),
      },
      emailReset: '',
      password: '',
      user:null,
      snackbar:{
        status:false,
        text:'',
        color:'',
      }
    }),
    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.loading = this.$loading.show();
          let userModel = new User();
          userModel.findByEmail(this.email).get().then(querySnapshot => {
            this.loading.hide();
            if (querySnapshot.empty) {
              this.error = {
                message:'El usuario o password son incorrectos'
              }
            } else {
              let auth = firebase.auth();
              auth.signInWithEmailAndPassword(this.email, this.password).then((user) => {
                console.log('user', user);
                querySnapshot.forEach((doc) => {
                  this.user  = doc.data();
                });
              })
              .catch((error) => {
                console.log(error);
                this.loading.hide();
                this.error = {message:error.message};
              });
            }
          })
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      resetPassword() {
        if (this.$refs.formResetPass.validate()) {
          this.loading = this.$loading.show();
          firebase.auth().sendPasswordResetEmail(this.emailReset)
          .then(() => {
            this.snackbar.status = true
            this.snackbar.color = 'success'
            this.snackbar.text = `Le hemos enviado un correo a ${this.emailReset}, por favor siga las instrucciones para restablecer su contraseña`
            this.loading.hide()
          })
          .catch((error) => {
            this.loading.hide()
            this.snackbar.status = true
            this.snackbar.color = 'red'
            let message = error.message
            if (error.code == 'auth/user-not-found') {
              message = `El ${this.emailReset} no esta registrado, por favor verifique sus datos`
            }
            this.snackbar.text = message
            console.log('error.code', error.code);
            console.log('error.message', error.message);
          });
        }
      }
    },
    watch:{
      user:{
        deep:true,
        immediate:true,
        handler() {
          if (!lodash.isNull(this.user)) {
            this.loading.hide();
            console.log('this.user.role', this.user.role);
            if (this.user.role == 'admin') {
              this.$router.push({path:'/backend/admin'});
            } else if (this.user.role == 'taquilla') {
              this.$router.push({path:'/backend/taquilla'});
            } else if (this.user.role == 'diver') {
              this.$router.push({path:'/backend/divers'});
            } else {
              console.log('error');
            }
          }
        }
      }
    },
    created () {
      const hostname = window.location.hostname;
      if (hostname === 'panel.cenoteszapote.com') {
        this.$store.commit('SET_LAYOUT', 'front')
      } else {
        this.isDivers = true
      }
    }
  }
</script>
<style>
  .text-danger{
    color: #F44336;
  }
</style>